import React, { useEffect, useState, useContext } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { WebsocketContext } from '../context/WebsocketContext';

const WebsocketAlerts = () => {
    const { ws } = useContext(WebsocketContext);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (ws) {
            const handleMessage = (event) => {
                const data = event.data;
                const message = JSON.parse(data);
                if (message.type === "UpdatedEvses") {
                    return;
                }
                // Set each alert to auto-dismiss after 15 seconds
                setAlerts(prevAlerts => [...prevAlerts, data]);
                setTimeout(() => {
                    setAlerts(oldAlerts => oldAlerts.filter(alert => alert !== data));
                }, 15000);
            }

            ws.addEventListener('message', handleMessage);

            // cleanup after component unmounts
            return () => {
                ws.removeEventListener('message', handleMessage);
            };
        }
    }, [ws]);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                zIndex: 99999,
                width: '100%',
            }}
        >
            {alerts.map((message, index) => (
                <UncontrolledAlert
                    key={index}
                    color="default"
                    fade={true}
                    style={{ margin: '10px 0' }} // To add gap between alerts
                >
                    <div className='d-flex align-items-center'>
                        <span className="alert-inner--icon">
                            <i className="ni ni-bell-55"></i>
                        </span>
                        <pre className="alert-inner--text w-100 text-light text-xs"
                            style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", overflowX: "hidden" }}>
                            {message}
                        </pre>
                    </div>
                </UncontrolledAlert>
            ))}
        </div>
    );
}

export default WebsocketAlerts;