import axios from './index';

class AuthorizeRemoteApi {

    static AuthorizeRemoteStart(data) {
        return axios.post(`${baseUrl}/start`, data);
    }

    static AuthorizeRemoteStop(data) {
        return axios.post(`${baseUrl}/stop`, data);
    }
}

const baseUrl = '/api/authorize-remote'

export default AuthorizeRemoteApi;