const WEBSOCKET_PROTOCOL = process.env.REACT_APP_API_URL.startsWith('https') ? 'wss' : 'ws';
const WEBSOCKET_BASE_URL = `${WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_API_URL.split('//')[1]}`;

export function initializeWebsocket(clientId) {
    let ws = new WebSocket(WEBSOCKET_BASE_URL);
    let connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
        console.log("Socket connected.");
        ws.send(JSON.stringify({ clientId }));
        clearTimeout(connectInterval); // clear Interval on successful connection
    };

    // websocket onclose event listener
    ws.onclose = e => {
        console.log("Socket closed.", e.reason);
        if (e.reason !== "Component unmounting") {
            console.log("Reconnect will be attempted in 5 seconds.");
            connectInterval = setTimeout(check, 5000);
        } else {
            clearTimeout(connectInterval);
        }
    };

    // websocket onerror event listener
    ws.onerror = err => {
        console.error("Socket encountered error: ", err.message, "Closing socket");
        ws.close();
    };

    const check = () => {
        //check if websocket instance is closed and create a new one when needed.
        if (!ws || ws.readyState === WebSocket.CLOSED) initializeWebsocket(clientId);
    };

    return ws;
}