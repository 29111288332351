import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ClientProvider } from './context/ClientContext';
import { WebsocketProvider } from './context/WebsocketContext';

import Index from "pages/Index/Index.js";
import Sections from "views/Sections.js";
import Presentation from "views/Presentation.js";
import AboutUs from "views/examples/AboutUs.js";
import AccountSettings from "views/examples/AccountSettings.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ChatPage from "views/examples/ChatPage.js";
import CheckoutPage from "views/examples/CheckoutPage.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Error from "views/examples/Error.js";
import Error500 from "views/examples/Error500.js";
import InvoicePage from "views/examples/InvoicePage.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import PricingPage from "views/examples/PricingPage.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ResetPage from "views/examples/ResetPage.js";
import Evse from "pages/Evse/Evse";
import WebsocketAlerts from "components/WebsocketAlerts";

function App() {
    const [libraryLoaded, setLibraryLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            setLibraryLoaded(true); /* set state to trigger re-render. */
        }
    }, []);

    if (!libraryLoaded) return null;

    return (
        <BrowserRouter>
            <ClientProvider>
                <WebsocketProvider>
                    <WebsocketAlerts />
                    <Routes>
                        <Route path="/sections/*" exact element={<Sections />} />
                        <Route path="/presentation" exact element={<Presentation />} />
                        <Route path="/about-us" exact element={<AboutUs />} />
                        <Route path="/account-settings" exact element={<AccountSettings />} />
                        <Route path="/blog-post" exact element={<BlogPost />} />
                        <Route path="/blog-posts" exact element={<BlogPosts />} />
                        <Route path="/chat-page" exact element={<ChatPage />} />
                        <Route path="/checkout-page" exact element={<CheckoutPage />} />
                        <Route path="/ecommerce" exact element={<Ecommerce />} />
                        <Route path="/error" exact element={<Error />} />
                        <Route path="/error-500" exact element={<Error500 />} />
                        <Route path="/invoice-page" exact element={<InvoicePage />} />
                        <Route path="/landing-page" exact element={<LandingPage />} />
                        <Route path="/login-page" exact element={<LoginPage />} />
                        <Route path="/pricing-page" exact element={<PricingPage />} />
                        <Route path="/product-page" exact element={<ProductPage />} />
                        <Route path="/profile-page" exact element={<ProfilePage />} />
                        <Route path="/register-page" exact element={<RegisterPage />} />
                        <Route path="/reset-page" exact element={<ResetPage />} />
                        <Route path="/presentation" element={<Navigate />} />
                        <Route path="/evse/:id" element={<Evse />} />
                        <Route path="/" exact element={<Index />} />
                        <Route path="*" element={<Navigate to="/error" replace />} />
                    </Routes>
                </WebsocketProvider>
            </ClientProvider>
        </BrowserRouter>
    );
}

export default App;