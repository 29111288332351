/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import useSupercluster from 'use-supercluster';
import { WebsocketContext } from 'context/WebsocketContext';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Spinner
} from "reactstrap";
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import EvsesApi from "api/evses";
import { CONSTANTS } from "utils/constants";

const { PROVIDER_ID } = CONSTANTS;

const Index = () => {
  const { ws } = useContext(WebsocketContext);
  const [evses, setEvses] = useState([]);
  const [filteredEvses, setFilteredEvses] = useState([]);
  const [selectedEvse, setSelectedEvse] = useState(null);
  const [error, setError] = useState(null);
  const [lastFetch, setLastFetch] = useState(null);
  const [latitude, setLatitude] = useState(50.115803);
  const [longitude, setLongitude] = useState(8.690230);
  const [address, setAddress] = useState("");
  const [evSearch, setEvSearch] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [onlyAvailable, setOnlyAvailable] = useState(true);

  const navigate = useNavigate();

  // Fetch EVSE data after the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const force = urlParams.get('force') === 'true';

      try {
        const response = await EvsesApi.PullEvseData({
          AuthenticationModes: [],
          Accessibility: [],
          CalibrationLawDataAvailability: [],
          GeoCoordinatesResponseFormat: "Google",
          ProviderID: PROVIDER_ID,
          force: force
        });

        const evsesWithOffsets = getEvsesWithOffsets(response.data.evses);

        // Store the EVSEs data with offsets and set loading state to false
        setEvses(evsesWithOffsets);
        setFilteredEvses(
          onlyAvailable
            ? evsesWithOffsets.filter((item) => item.EvseStatus === "Available")
            : [...evsesWithOffsets]
        );
        setLastFetch(response.data.lastFetch);
        setFetching(false);
      } catch (err) {
        handleError(err);
      }
    };

    fetchData();
  }, []);

  // WebSocket logic
  useEffect(() => {
    if (!ws) {
      return;
    }

    const handleMessage = (event) => {
      const wsMessage = JSON.parse(event.data);
      if (wsMessage.type === "UpdatedEvses") {
        const updatedEvses = wsMessage.data;

        const updatedEvsesWithOffsets = getEvsesWithOffsets(updatedEvses);

        setEvses(updatedEvsesWithOffsets);

        setFilteredEvses(
          onlyAvailable
            ? updatedEvsesWithOffsets.filter((item) => item.EvseStatus === "Available")
            : [...updatedEvsesWithOffsets]
        );

        console.log('Updated EVSES statuses');
      }
    };

    ws.addEventListener('message', handleMessage);

    // Clean up on component unmount
    return () => {
      ws.removeEventListener('message', handleMessage);
    };
  }, [ws, onlyAvailable]);

  // Map data to evses offset and return it
  const getEvsesWithOffsets = (evses) =>
    evses.map((evse) => {
      const [latitude, longitude] = evse.GeoCoordinates.Google.Coordinates.split(' ').map(Number);
      const noiseLatitude = Math.random() > 0.5 ? Math.random() / 50000 : -Math.random() / 50000;
      const noiseLongitude = Math.random() > 0.5 ? Math.random() / 50000 : -Math.random() / 50000;

      return {
        ...evse,
        GeoCoordinates: {
          ...evse.GeoCoordinates,
          Google: { Coordinates: `${latitude + noiseLatitude} ${longitude + noiseLongitude}` }
        }
      };
    });

  // Handle error from API request
  const handleError = (err) => {
    console.error(err);
    setFetching(false);
    if (err && err.message) {
      const serviceError = `\nService error: ${JSON.stringify(err.response?.data?.serviceError || err.response?.data)}`;
      const validationErrors = err.response?.data?.validationErrors
        ? `\nValidation errors: ${JSON.stringify(err.response?.data?.validationErrors)}`
        : '';
      return setError(err.message + serviceError + validationErrors);
    }
    setError(`There has been an error: ${err.message ? err.message : JSON.stringify(err)}`);
  }

  useEffect(() => {
    getUserLocation();
  }, [])

  useEffect(() => {
    if (onlyAvailable) {
      setFilteredEvses(evses.filter(item => item.EvseStatus === "Available"));
    } else {
      setFilteredEvses(evses.slice());
    }
  }, [evses, onlyAvailable])

  // Obtain the user's location and update the map view
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setZoom(12);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  // Whenever the address changes it will call this function
  const handleAddressChange = (address) => {
    setAddress(address);
    const searchLowerCase = address.toLowerCase();
    const matchedEv = filteredEvses.filter(evse =>
      evse.EvseID.toLowerCase().includes(searchLowerCase) ||
      (evse.ChargingStationNames[0]?.value.toLowerCase().includes(searchLowerCase) && evse.Address)
    );
    setEvSearch(matchedEv);
  };

  // After selecting the address on dropdown, it will call this function
  const handleAddressSelect = (address) => {
    setAddress(address);
    try {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          setLatitude(latLng.lat);
          setLongitude(latLng.lng);
        });
      setZoom(13);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const handleEvseSelect = (ev) => {
    const evAddress = `${ev.ChargingStationNames[0]?.value} - ${ev.Address.City}, ${ev.Address.Street}`;
    setAddress(evAddress);
    setEvSearch([]); // Clear the EVSE dropdown list
    setSelectedEvse(ev);
    const latLong = ev.GeoCoordinates.Google.Coordinates.split(' ');

    // Ensure we have 2 coordinates before setting the state
    if (latLong.length === 2) {
      setLatitude(parseFloat(latLong[0]));
      setLongitude(parseFloat(latLong[1]));
    } else {
      console.error('Invalid coordinates provided');
    }
    // Scroll to card
    try {
      setTimeout(() => {
        document.getElementById('evse-card').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleAvailable = () => {
    setOnlyAvailable(!onlyAvailable);
  };

  return (
    <>
      <DemoNavbar type="primary" hide={true} />
      <div className="pb-5"></div>
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <Col>
                <h1 className="display-3 mb-0">
                  Find EV Charging Stations
                </h1>
                <p className="lead mt-1 pb-2">
                  Easily locate {filteredEvses?.length ? filteredEvses.length : null} <b>eDRV Inc. {!fetching && `(${moment(lastFetch).format('MMMM Do YYYY, h:mm:ss a')})`} Charge Stations.</b><br className="d-xs-none" /> Enter an ID or address to get started.</p>
                <Row className="align-items-center">
                  <Col lg="5" xs="12" style={{ zIndex: 1000 }}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={handleAddressChange}
                      onSelect={handleAddressSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="position-relative">
                          <input
                            {...getInputProps({
                              placeholder: `${fetching ? "Finding Chargestations..." : "Chargestation ID or address"}`,
                              className: 'form-control form-control-lg',
                              disabled: fetching
                            })}
                          />
                          {fetching && <Spinner type="grow" className="position-absolute" size="sm" style={{ right: '15px', top: '50%', transform: 'translateY(-50%)' }} />}

                          {!address && !fetching ?
                            <i
                              className="fas fa-map-marker-alt position-absolute c-pointer"
                              style={{ right: '15px', top: '50%', transform: 'translateY(-50%)' }}
                              onClick={getUserLocation}
                            ></i>
                            : null
                          }

                          {address && (
                            <i
                              className="fas fa-times position-absolute c-pointer"
                              style={{ right: '15px', top: '50%', transform: 'translateY(-50%)' }}
                              onClick={() => {
                                setAddress('');
                                setEvSearch([]);
                              }}
                            ></i>
                          )}
                          <div className="autocomplete-dropdown-container position-absolute w-100 bg-white border rounded-sm overflow-auto" style={{ maxHeight: '250px' }}>
                            {loading && <div>Loading...</div>}
                            {address && (suggestions.map((suggestion, index) => (
                              <div key={index} {...getSuggestionItemProps(suggestion)} className="p-2 border-bottom c-pointer">
                                <i className="fas fa-map-marker-alt text-primary mr-2"></i>
                                {suggestion.description}
                              </div>
                            )))}
                            {address && (evSearch.map((ev, index) => (
                              <div key={index} onClick={() => handleEvseSelect(ev)} className="p-2 border-bottom c-pointer">
                                <i className="fas fa-bolt text-warning mr-2"></i>
                                {ev.ChargingStationNames[0]?.value} - {ev.Address.City}, {ev.Address.Street}
                              </div>
                            )))}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {error ? <h6 className="text-center mb-0 mt-2 text-danger font-weight-400">{error}</h6> : null}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <div className="d-flex align-items-center">
                      <label className="custom-toggle custom-toggle-default mr-2">
                        <input type="checkbox" checked={onlyAvailable} onChange={toggleAvailable} disabled={fetching} />
                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes" />
                      </label>
                      <span>Show only Available Stations</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt--4">
          <Map center={{ lat: latitude, lng: longitude }} zoom={zoom} setZoom={setZoom} evses={filteredEvses} selectedEvse={selectedEvse} setSelectedEvse={setSelectedEvse} navigate={navigate} />
        </div>
        <DemoFooter />
      </div>
    </>
  );
}

const EvseMarker = ({ evse, setSelectedEvse }) => {
  const name = evse && evse.ChargingStationNames && evse.ChargingStationNames[0] ? evse.ChargingStationNames[0]?.value : 'N/A';
  let markerColor = "text-default";

  // Check on EVSE status to determine color
  if (evse.EvseStatus !== 'Available') {
    markerColor = "text-danger"; // This will set the color to red
  }

  const scroll = () => {
    // Scroll to card
    try {
      setTimeout(() => {
        document.getElementById('evse-card').scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div
      className={`d-flex align-items-center flex-nowrap ${markerColor}`}
      onClick={() => { setSelectedEvse(evse); scroll() }}
    >
      <i className="fas fa-charging-station mr-2" style={{ fontSize: "25px" }} />
      <p className="mb-0 ws-0 font-weight-600">{name}</p>
    </div>
  );
};

const ClusterMarker = ({ lat, lng, count, onClick, totalEvses }) => {
  const size = `${32 + (count / totalEvses) * 35}px`;

  return (
    <div
      className="cluster-marker mx-auto d-flex align-items-center justify-content-center bg-default text-white rounded-circle"
      style={{
        width: size,
        height: size
      }}
      onClick={onClick}
    >
      {count}
    </div>
  );
}

const EvseDetailsCard = ({ evse, hideDetails, navigate }) => {
  if (!evse) {
    return null;
  }

  const status = evse.EvseStatus;

  return (
    <Card id="evse-card" className="position-absolute bottom-0 start-50 translate-middle-x mb-0 mx-auto" style={{ zIndex: 1, width: '100%' }}>
      <CardHeader className="pb-1">
        <Row>
          <Col xs="8" className="text-left">
            <h5 className="mb-0">{evse.ChargingStationNames[0]?.value}</h5>
          </Col>
          <Col className="text-right">
            <i
              className="fas fa-times c-pointer"
              onClick={hideDetails}
              style={{ fontSize: "25px" }}
            ></i>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <h6 className="card-subtitle mb-2 text-muted">
              {`${evse.Address.Street}, ${evse.Address.City}, ${evse.Address.PostalCode}`}
            </h6>
            <h6 className="card-subtitle mb-2 text-muted">
              {`Connectors: ${evse.Plugs.join(', ')}`}
            </h6>
            <h6 className="card-subtitle mb-2 text-muted">
              ID: {evse.EvseID}
            </h6>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="border-0 pt-0">
        <Button disabled={status !== "Available"} onClick={() => navigate(`/evse/${evse.EvseID}`)} className="w-100" color={status !== "Available" ? "warning" : "primary"}>Station {status !== "Available" ? status : "Details"}</Button>
      </CardFooter>
    </Card>
  );
};

const Map = ({ center, zoom, setZoom, evses, selectedEvse, setSelectedEvse, navigate }) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);

  const points = evses.map(evse => {
    const [latitude, longitude] = evse.GeoCoordinates.Google.Coordinates.split(' ').map(Number);
    return {
      type: "Feature",
      properties: { cluster: false, evse },
      geometry: {
        type: "Point",
        coordinates: [
          longitude,
          latitude
        ]
      }
    };
  });

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const hideDetails = () => setSelectedEvse(null);

  return (
    <div style={{ position: "relative", height: "600px", width: "100%" }}>
      <GoogleMapReact
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ scrollwheel: true }}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, evse } = cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={cluster.id}
                lat={latitude}
                lng={longitude}
                count={cluster.properties.point_count}
                totalEvses={evses.length}
                onClick={() => {
                  const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                  mapRef.current.setZoom(expansionZoom);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
              />
            );
          }

          return (
            <EvseMarker
              lat={latitude}
              lng={longitude}
              evse={evse}
              key={evse.EvseID}
              setSelectedEvse={setSelectedEvse}
            />
          )
        })}
      </GoogleMapReact>
      <EvseDetailsCard evse={selectedEvse} hideDetails={hideDetails} navigate={navigate} />
    </div>
  );
}

export default Index;