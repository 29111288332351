import axios from './index';

class EvsesApi {

    static PullEvseData(data) {
        return axios.post(`${baseUrl}/data`, data);
    }

    static PullEvseStatus(data) {
        return axios.post(`${baseUrl}/status`, data);
    }

    static PullEvseStatusById(data) {
        return axios.post(`${baseUrl}/status/id`, data);
    }

    static PullEvseStatusByOperatorId(data) {
        return axios.post(`${baseUrl}/status/operator-id`, data);
    }

    static PullEvsePricing(data) {
        return axios.post(`${baseUrl}/pricing`, data);
    }

    static GetEvseById(id) { // <- internal
        return axios.get(`${baseUrl}/${id}`);
    }
}

const baseUrl = '/api/evses'

export default EvsesApi;