/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { initializeWebsocket } from '../config/websocket';
import { ClientContext } from '../context/ClientContext';

export const WebsocketContext = createContext();

export const WebsocketProvider = ({ children }) => {
    const { client } = useContext(ClientContext);
    const [ws, setWs] = useState(null);

    useEffect(() => {
        if (client && client._id && !ws) {
            const wsInstance = initializeWebsocket(client._id);
            setWs(wsInstance);
        }

        return () => {
            if (ws) {
                ws.close(1000, "Component unmounting");
            }
        }
    }, [client, ws]);

    return (
        <WebsocketContext.Provider value={{ ws }}>
            {children}
        </WebsocketContext.Provider>
    );
};