import React from "react";
import ReactDOM from "react-dom";
import App from './App';

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";
import "assets/scss/custom.scss";

ReactDOM.render(<App />, document.getElementById('root'));