import Axios from 'axios';

// Set API proxy base URL based on environment
const baseURL = process.env.REACT_APP_API_URL;

const axios = Axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' }
});

axios.interceptors.request.use(
    (config) => {
        // Add Authorization header to requests
        // config.headers.Authorization = 'Token'
        return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
