import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import Accordion1 from "components/accordions/Accordion1.js";

function AccordionsSection() {
  return (
    <>
      <div className="cd-section" id="accordion">
        <Accordion1 />
      </div>
    </>
  );
}

export default AccordionsSection;
