import axios from './index';

class SessionsApi {

    static CreateSession(data) {
        return axios.post(`${baseUrl}`, data);
    }

    static GetSession(sessionId) {
        return axios.get(`${baseUrl}/${sessionId}`);
    }

    static GetSessionBySessionId(serviceSessionId) {
        return axios.get(`${baseUrl}/session/${serviceSessionId}`);
    }

    static UpdateSession(id, data) {
        return axios.put(`${baseUrl}/${id}`, data);
    }

    static PartialUpdateSession(id, data) {
        return axios.patch(`${baseUrl}/${id}`, data);
    }

    static DeleteSession(id) {
        return axios.delete(`${baseUrl}/${id}`);
    }

}

const baseUrl = '/api/sessions'

export default SessionsApi;