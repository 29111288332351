export const formatOpeningTimes = (openingTimes) => {
    if (!openingTimes || openingTimes.length === 0) {
        // return a default string or null if no openingTimes
        return 'Not Available';
    } else {
        let formattedTimes = [];
        let i = 0;
        while (i < openingTimes.length) {
            let j = i;
            while (j < openingTimes.length && openingTimes[j].Period[0].begin === openingTimes[i].Period[0].begin &&
                openingTimes[j].Period[0].end === openingTimes[i].Period[0].end) {
                j++;
            }
            if (j - i === 1) {
                formattedTimes.push(`${openingTimes[i].on}: ${openingTimes[i].Period[0].begin} - ${openingTimes[i].Period[0].end}`);
            } else {
                formattedTimes.push(`${openingTimes[i].on} - ${openingTimes[j - 1].on}: ${openingTimes[i].Period[0].begin} - ${openingTimes[i].Period[0].end}`);
            }
            i = j;
        }
        return formattedTimes.join(", ");
    }
}

export const navigateAddress = (evse) =>
    `http://maps.google.com/?q=${encodeURIComponent(
        `${evse?.Address?.Street} ${evse?.Address?.City}, ${evse?.Address?.PostalCode}`
    )}`;