export function generateEvcoID(providerID) {

    // Generate a random string of 8 characters for the contract ID
    const contractID = Math.random().toString(36).slice(-8);

    // Generate a random number between 0 and 9 for the checksum
    const checksum = Math.floor(Math.random() * 10);

    // Construct the evcoID by concatenating the providerID, contractID, and checksum
    const evcoID = `${providerID}-C${contractID}-${checksum}`;

    return evcoID;
}

