import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
    Container,
    Row,
    Col,
    Spinner,
    Card,
    CardBody,
    Button,
    CardFooter,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import { formatOpeningTimes, navigateAddress } from "utils/evse";
import { ClientContext } from 'context/ClientContext';
import { WebsocketContext } from 'context/WebsocketContext';
import EvsesApi from "api/evses";
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import AuthorizeRemoteApi from "api/authorize-remote";
import SessionsApi from "api/sessions";
import { CONSTANTS } from "utils/constants";

const { PROVIDER_ID, PartnerProductID } = CONSTANTS;

const Evse = () => {
    const { id } = useParams(); // Get the ID from URL
    const { client } = useContext(ClientContext);
    const { ws } = useContext(WebsocketContext);
    const [evse, setEvse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [starting, setStarting] = useState(false);
    const [stopping, setStopping] = useState(false);
    const [charging, setCharging] = useState(false);
    // Mongo document _id
    const [sessionId, setSessionId] = useState("");
    const [serviceSessionId, setServiceSessionId] = useState("");
    const [sessionStartTime, setSessionStartTime] = useState(null);
    const [cdr, setCdr] = useState(null);
    const [chargingMsg, setChargingMsg] = useState("Available");
    const [error, setError] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // WebSocket logic
    useEffect(() => {
        if (ws) {
            const handleMessage = (event) => {
                const wsMessage = JSON.parse(event.data);
                if (wsMessage.type === "Charge Detail Record") {
                    const data = wsMessage.data;
                    setCdr(data); // Update the cdr state with the new data
                }
            };

            ws.addEventListener('message', handleMessage);

            // cleanup after component unmounts
            return () => {
                ws.removeEventListener('message', handleMessage);
            };
        }
    }, [ws]);

    useEffect(() => {
        // Create a new abort controller
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                const response = await EvsesApi.GetEvseById(id);
                const evse = response.data;

                // Attempting to pull status only when the first call succeeds.
                try {
                    const status_response = await EvsesApi.PullEvseStatusById({
                        EvseID: [evse.EvseID],
                        ProviderID: PROVIDER_ID
                    });
                    const evseStatus = status_response.data.EVSEStatusRecords.EvseStatusRecord[0].EvseStatus;
                    evse.EvseStatus = evseStatus; // Attach the status to the evse object
                    setChargingMsg(evseStatus);
                } catch (err) {
                    console.error(`Error while pulling EVSE status: ${err}`);
                    evse.EvseStatus = 'Unknown'; // Set to 'Unknown' if status cannot be retrieved
                    setChargingMsg('Unknown');
                }

                console.log(evse);
                setEvse(evse);

                const urlParams = new URLSearchParams(window.location.search);
                const sessionIdFromUrl = urlParams.get('sessionId');
                if (sessionIdFromUrl) {
                    // The page has been refreshed, and there is a sessionId in the URL
                    // Fetch the session details and update the state
                    try {
                        const response = await SessionsApi.GetSession(sessionIdFromUrl);
                        const session = response.data;
                        if (session && session.Status === 'Active') {
                            setSessionId(sessionIdFromUrl);
                            setServiceSessionId(session.SessionID);
                            setCharging(true);
                        }
                    } catch (e) {
                        console.error(`Error while fetching session: ${e}`);
                    }
                }
            } catch (err) {
                console.error(`Error while getting EVSE by Id: ${err}`);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchData().catch(err => console.error(err));

        return () => {
            // Abort any fetches on component cleanup
            abortController.abort();
        };
    }, [id]);

    const authorizeRemoteStart = async () => {
        if (starting) {
            return;
        }
        try {
            setStarting(true);
            const response = await AuthorizeRemoteApi.AuthorizeRemoteStart({
                EvseID: evse.EvseID,
                Identification: {
                    RemoteIdentification: {
                        EvcoID: client?.EvcoID
                    }
                },
                OperatorID: evse.OperatorID,
                ProviderID: PROVIDER_ID,
                PartnerProductID: PartnerProductID,
                Email: email,
                Password: password
            });

            setStarting(false);
            setCharging(true);
            setChargingMsg("Charging");
            setServiceSessionId(response.data.SessionID);
            setSessionStartTime(new Date());

            // Create a new sessions document
            const currentTime = new Date();
            const session_response = await SessionsApi.CreateSession({
                SessionID: response.data.SessionID,
                ProviderID: PROVIDER_ID,
                OperatorID: evse.OperatorID,
                EvseID: evse.EvseID,
                ClientID: client?._id,
                EvcoID: client?.EvcoID,
                StartTime: currentTime,
                Status: 'Active'
            });

            setSessionId(session_response.data._id);
            // Update URL with sessionId
            window.history.pushState(
                null,
                '',
                window.location.href + `?sessionId=${session_response.data._id}`
            );
        } catch (err) {
            console.error(err);
            setStarting(false);
            setCharging(false);
            if (err && err.message) {
                const serviceError = `\nService error: ${JSON.stringify(err.response.data.serviceError || err.response.data)}`;
                const validationErrors = err.response?.data?.validationErrors
                    ? `\nValidation errors: ${JSON.stringify(err.response.data.validationErrors)}`
                    : '';
                return setError(err.message + serviceError + validationErrors);
            }
            return setError("There has been an error");
        }
    }

    const authorizeRemoteStop = async () => {
        if (stopping) {
            return;
        }
        try {
            setStopping(true);
            await AuthorizeRemoteApi.AuthorizeRemoteStop({
                EvseID: evse.EvseID,
                Identification: {
                    RemoteIdentification: {
                        EvcoID: client?.EvcoID
                    },
                },
                ProviderID: PROVIDER_ID,
                OperatorID: evse.OperatorID,
                SessionID: serviceSessionId
            });
            setEvse({ ...evse, EvseStatus: "Available" });
            setStopping(false);
            setCharging(false);
            setChargingMsg("Available");
            // Remove sessionId from URL 
            const url = window.location.href.split('?')[0];
            window.history.pushState(null, '', url);

            // Find and update the sessions document
            await SessionsApi.UpdateSession(sessionId, {
                EndTime: new Date(),
                Status: 'Ended'
            });
        } catch (err) {
            console.error(err);
            setStopping(false);
            await SessionsApi.UpdateSession(sessionId, { Error: JSON.stringify(err) });
            if (err && err.message) {
                const serviceError = `\nService error: ${JSON.stringify(err.response.data.serviceError || err.response.data)}`;
                const validationErrors = err.response?.data?.validationErrors
                    ? `\nValidation errors: ${JSON.stringify(err.response.data.validationErrors)}`
                    : '';
                return setError(err.message + serviceError + validationErrors);
            }
            return setError("There has been an error");
        }
    }

    const handleAuthorize = (event) => {
        event.preventDefault();
        setError(null);
        authorizeRemoteStart();
        setModalVisible(false);
    };

    return (
        <>
            <DemoNavbar type="primary" hide={true} />
            <div className="pb-7"></div>
            <div className="wrapper">
                <Container fluid>
                    {loading ?
                        <Row>
                            <Col className="text-center">
                                <Spinner />
                            </Col>
                            <div className="page-header"></div>
                        </Row>
                        :
                        <>
                            {evse && evse.EvseID ?
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody className="pb-3">
                                                <Row>
                                                    <Col xs="8" className="text-left">
                                                        <div className="d-flex align-items-center">
                                                            <i className="fas fa-map-marker-alt mr-2" />
                                                            <h6 className="mb-0">{evse.Address.Street} {evse.Address.City}, {evse.Address.PostalCode} <br /> <span className="text-xs">{evse.Address.Country}</span></h6>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4" className="text-right">
                                                        <div className="d-flex align-items-center justify-content-end c-pointer text-primary">
                                                            <a
                                                                href={navigateAddress(evse)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="d-flex align-items-center justify-content-end c-pointer text-primary"
                                                            >
                                                                <i className="fas fa-location-arrow text-lg mr-1" />
                                                                <small className="text-sm mb-0 font-weight-500">Navigate</small>
                                                            </a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter className="pt-3 pb-3">
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <i className="fas fa-universal-access mr-2" />
                                                                <small className="mb-0">Accessibility</small>
                                                            </div>
                                                            <small className="mb-0 text-muted">{evse.Accessibility}</small>
                                                        </div>
                                                        {evse.PaymentOptions && <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <i className="far fa-credit-card mr-2" />
                                                                <small className="mb-0">Payment type</small>
                                                            </div>
                                                            <small className="mb-0 text-muted">{evse.PaymentOptions.join(", ")}</small>
                                                        </div>}
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                        <Card>
                                            <CardHeader>
                                                <Row className="align-items-center">
                                                    <Col xs="8" className="text-left">
                                                        <h6 className="mb-0 font-weight-600">{evse.ChargingStationNames[0]?.value || "N/A"}</h6>
                                                    </Col>
                                                    <Col className="text-right">
                                                        <small className="mb-0 text-muted text-sm">{chargingMsg}</small>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col xs="8" className="text-left">
                                                        <div className="d-flex align-items-center">
                                                            <i className="fas fa-charging-station mr-2" />
                                                            <div className="d-flex flex-column">
                                                                <h5 className="mb-0">{evse.ChargingFacilities[0].Power || 'Not provided'} kW</h5>
                                                                <h6 className="mb-0">{evse.ChargingFacilities[0].PowerType}</h6>
                                                                <h6 className="mb-0">{evse.Plugs[0]}</h6>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="4" className="text-right d-flex flex-column">
                                                        <small className="text-muted">
                                                            {evse.IsOpen24Hours ? 'Open 24 hours'
                                                                :
                                                                `Open ${formatOpeningTimes(evse.OpeningTimes)}`}
                                                        </small>
                                                        <small className="text-muted">{evse.EvseID}</small>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                {charging || cdr ? <ChargingEVCard cdr={cdr} setCdr={setCdr} charging={charging} sessionStartTime={sessionStartTime} serviceSessionId={serviceSessionId} /> : null}
                                                <Row>
                                                    <Col className="text-center">
                                                        {error ? <h6 className="mb-2 text-danger text-center">{error}</h6> : null}
                                                        {charging ?
                                                            <Button onClick={authorizeRemoteStop} size="lg" className="w-100" color="warning">{stopping ? <Spinner size="sm" /> : "Stop Charging"}</Button>
                                                            :
                                                            <Button disabled={evse.EvseStatus !== "Available"} onClick={() => setModalVisible(true)} size="lg" className="w-100" color="primary">{starting ? <Spinner size="sm" /> : <>{evse.EvseStatus !== "Available" ? `Station ${evse.EvseStatus}` : "Start Charging"}</>}</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <i className="fas fa-house-user mr-2" />
                                                                <p className="mb-0">Operator</p>
                                                            </div>
                                                            <p className="mb-0">{evse.OperatorName}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <i className="fas fa-phone mr-2" />
                                                                <p className="mb-0">Support phone</p>
                                                            </div>
                                                            <a href={`tel:${evse.HotlinePhoneNumber}`}>{evse.HotlinePhoneNumber}</a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col className="text-center">
                                        <h3>No Evse was found with this ID: {id}</h3>
                                        <div className="page-header"></div>
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    <AuthenticateModal
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        handleAuthorize={handleAuthorize}
                        email={email}
                        password={password}
                        setEmail={setEmail}
                        setPassword={setPassword}
                    />
                </Container>
                <DemoFooter />
            </div>
        </>
    );
};

const ChargingEVCard = ({ cdr, charging, sessionStartTime, serviceSessionId }) => {
    return (
        <>
            <Card>
                <CardBody>
                    {charging ?
                        <Row>
                            <Col className="text-center d-flex flex-column align-items-center justify-content-center">
                                <h4>Charging your Electric Vehicle</h4>
                                <p className="mb-0">Session Started: {moment(sessionStartTime).format('LLL')}</p>
                                {serviceSessionId ?? <p className="mb-0">SessionID: {serviceSessionId}</p>}
                                <img
                                    style={{ width: "120px" }}
                                    alt="..."
                                    src={require("assets/img/loading.gif")}
                                />
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col className="text-center d-flex flex-column align-items-center justify-content-center">
                                <h4>Charging Finished</h4>
                                <p className="mb-0">Session Started: {moment(cdr.ChargingStart).format('LLL')}</p>
                                <p className="mb-0">Session Ended: {moment(cdr.ChargingEnd).format('LLL')}</p>
                                {serviceSessionId ?? <p className="mb-0">SessionID: {serviceSessionId}</p>}
                                <p className="mb-0 mt-2 font-weight-600">Energy Consumed</p>
                                <div className="d-flex align-items-center">
                                    <h1 className="display-1">{cdr.ConsumedEnergy}</h1>
                                    <h3 className="mb-0 ml-2 font-weight-300">kw</h3>
                                </div>
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
        </>
    )
}

const AuthenticateModal = ({ modalVisible, setModalVisible, handleAuthorize, email, password, setEmail, setPassword }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Modal isOpen={modalVisible} toggle={() => setModalVisible(!modalVisible)}>
            <ModalHeader toggle={() => setModalVisible(!modalVisible)}>
                Authorize Remote Start
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleAuthorize}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fas fa-envelope" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required autoComplete="off" />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i style={{ cursor: 'pointer' }} onClick={togglePasswordVisibility} className={showPassword ? "fas fa-lock" : "fas fa-eye"} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={showPassword ? "text" : "password"} name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} required autoComplete="off" />
                        </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                        <Button type="submit" color="primary">
                            Start Charging
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
};

export default Evse;