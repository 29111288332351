import axios from './index';

class ClientsApi {

    static CreateClient(data) {
        return axios.post(`${baseUrl}`, data);
    }

    static GetClient(clientId) {
        return axios.get(`${baseUrl}/${clientId}`);
    }

    static GetClientByEvcoID(evcoId) {
        return axios.get(`${baseUrl}/client/${evcoId}`);
    }

    static UpdateClient(id, data) {
        return axios.put(`${baseUrl}/${id}`, data);
    }

    static PartialUpdateClient(id, data) {
        return axios.patch(`${baseUrl}/${id}`, data);
    }

    static DeleteClient(id) {
        return axios.delete(`${baseUrl}/${id}`);
    }

}

const baseUrl = '/api/clients';

export default ClientsApi;