import React, { createContext, useState, useEffect, useCallback } from 'react';
import ClientsApi from 'api/clients';
import { generateEvcoID } from '../utils/contract';
import { CONSTANTS } from '../utils/constants';

const { PROVIDER_ID } = CONSTANTS;

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
    const [client, setClient] = useState(null);

    const generateClient = useCallback(async () => {
        try {
            const res = await ClientsApi.CreateClient({ EvcoID: generateEvcoID(PROVIDER_ID) });
            localStorage.setItem('client', res.data._id);
            setClient(res.data);
        } catch (error) {
            console.error(`Failed to generate new client - ${error}`);
        }
    }, [])

    const getClient = useCallback(async (clientId) => {
        try {
            const res = await ClientsApi.GetClient(clientId);
            if (res.data) {
                setClient(res.data);
            } else {
                console.log(`Client ID: ${clientId} not found in database. Generating new client.`)
                generateClient();
            }
        } catch (error) {
            console.error(`Failed to fetch client - ${error}. Generating new client.`);
            generateClient();
        }
    }, [generateClient]);

    useEffect(() => {
        const savedClientId = localStorage.getItem('client');
        if (savedClientId) {
            getClient(savedClientId);  // Fetch the client from the database
        } else {
            generateClient();  // Generate a new client
        }
    }, [getClient, generateClient]);

    return (
        <ClientContext.Provider value={{ client, setClient }}>
            {children}
        </ClientContext.Provider>
    );
};